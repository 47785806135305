const api = {
    ENDPOINT:
        process.env.NODE_ENV !== 'production' || process.env.USER === 'ubuntu'
            ? 'https://localhost:3000' : 'https://gr04api.webpack.tech',
    MODE: 'cors',
    HEADERS: {
        'content-type': 'application/json',
    },
};

export { api };