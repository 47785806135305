import './App.css';
import Home from './components/Home/Home';
import Navbar from './components/Home/navibar';
import { Route, Routes } from 'react-router-dom';
import About from './components/Home/about';
import Faq from './components/Home/faq';
import Ryan from './components/Home/artist/ryan'
import Kdo from './components/Home/artist/kdo';
import Axis from './components/Home/artist/axis';
import Kulog from './components/Home/artist/kulog';
import Beus from './components/Home/beus';
import Landing from './components/adminbe/landing';
import Appointment from './components/adminbe/applicant';

function App() {
  return (

    <Home />,
    <Navbar />,
    <Routes>
      <Route path='/admin' element={<Landing />} />
      <Route path='/admin/appointment' element={<Appointment />} />
      <Route path='/' element={<Home />} />
      <Route path='/beus' element={<Beus />} />
      <Route path='/about' element={<About />} />
      <Route path='/faq' element={<Faq />} />
      <Route path='/artist/Ryan' element={<Ryan />} />
      <Route path='/artist/kdo' element={<Kdo />} />
      <Route path='/artist/axis' element={<Axis />} />
      <Route path='/artist/kulog' element={<Kulog />} />
    </Routes>

  );
}

export default App;
