import React, { useEffect, useState } from 'react';

import { api } from '../../api';

function Appointment() {
    const [appointmentlist, setAppointmentlist] = useState([]);
    const ENDPOINT = `${api.ENDPOINT}/api/appointment`;
    console.log(ENDPOINT)
    useEffect(() => {
        fetch(ENDPOINT, {
            method: 'GET',
            mode: 'cors',

        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                // const newTasks = [...tasks, ...result];
                // let newTasks = [...tasks];
                // newTasks = [...newTasks, ...data];
                setAppointmentlist([...data]);
            })
            .catch((error) => console.log('error', error));
    }, []);

    return (


        <div
            className="container bg-outline-dark mt-5 border"
            style={{
                boxShadow: '0px 0px 15px -5px',
                borderRadius: '5px',
                fontSize: '0.8rem',
                backgroundColor: '#ffffff',
            }}
        >
            <div className="container">
                <div className="container my-5">
                    <h3>Applicants</h3>
                </div>

                <table className="table border border-2 table-responsive-lg table-responsive-md table-responsive-sm overflow-auto">
                    <thead>
                        <tr>
                            <th scope="col">Applicant ID</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Age</th>
                            <th scope="col">Address</th>
                            <th scope="col">Email</th>
                            <th scope="col">Artist ID</th>
                        </tr>
                    </thead>
                    <tbody style={{ fontSize: '0.8rem' }}>
                        {appointmentlist.map((appointmentlist, index) => (
                            <tr key={appointmentlist.id}>
                                <th scope="row">{appointmentlist.id}</th>
                                <td>{appointmentlist.irstname}</td>
                                <td>{appointmentlist.Lastname}</td>
                                <td>{appointmentlist.Age}</td>
                                <td>{appointmentlist.Address}</td>
                                <td>{appointmentlist.Email}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="container m-3 d-flex justify-content-end">
                    {/* <div className="mx-2">
                        <Applicantlist addTask={addTask} />
                    </div> */}
                </div>
            </div>
        </div>

    );
}

export default Appointment;