import Navibar from "./Navbar";
import Footer from "./footer";

function Faq() {
    return (
        <>
            <Navibar />
            <div className="container mt-5 pt-5 text-white">

                <h1>How Much Does It Cost?</h1>
                <p>This may vary depends on the artist popularity:</p>
                <figure>
                    <blockquote className="blockquote">
                        <p>Every shop charges differently. Therefore, there is no fixed price for a tattoo. However, you would have to pay a good amount of money if you want to get a high-quality tattoo. The tattoo price may vary, depending on the amount of work required and your vendor’s craftsmanship.

                            Settling with a cheap vendor can cost you in terms of design, quality, and safety. That You will probably want to have covered up later on.</p>
                    </blockquote><figcaption className="blockquote-footer mt-3">From doingtattoo website</figcaption></figure>


                <h1>Does It Hurt To Get A Tattoo?</h1>
                <p>Please be mindful that individually we have our own level of pain tolerance:</p>
                <figure>
                    <blockquote className="blockquote mt-3">
                        <p>This is the most common question asked by those intending to get a tattoo. This is because they have seen people poke a needle into someone’s skin. That creates a fear of pain in their minds. We won’t give you false hope. It does hurt a little, but it depends on your pain tolerance level.

                            It also depends on the areas you are getting a tattoo, such as the inside of arms, ankles, or outer shoulders. Some people who have experienced what it is like to get a tattoo often describe it as a hot scratching feeling. Numbing creams are not recommended for use before the tattoo. They may cause allergic reactions to your skin.</p>  </blockquote>
                    <figcaption className="blockquote-footer mt-3">From doingtattoo website</figcaption>
                </figure>



                <h1>Is It Sanitary To Have A Tattoo?</h1>
                <p>Hygienic and Clean.</p>
                <figure>
                    <blockquote className="blockquote">
                        <p>Tattooing is similar to a medical procedure. The needles used in the process puncture the skin and deposit the ink into dermis. Therefore they must be cleaned; otherwise, they can cause severe allergic reactions. Each shop must be checked with health agencies.

                            When it comes to equipment, they are pre-packaged and pre-sterilized. All machines and clip cords are covered in plastic wraps. The artist also wears gloves and a mask before coming near a client. So it is a sanitary procedure.</p>   </blockquote>
                    <figcaption className="blockquote-footer mt-3">From doingtattoo website</figcaption>
                </figure>

                <h1>How Long Is The Tattooing Procedure?</h1>
                <p>minimum is less than an hour</p>
                <figure>
                    <blockquote className="blockquote">
                        <p>This depends on the design you want to get, the experience of the artist, and patience. Tattooing is a procedure involving patience. There should be no rush to get it done faster as results won’t be as good as you thought they should be.

                            A piece of the size of your hand with an average complexity should not take more than two hours. But, a more complex and large design can take up to ten hours. A full arm can also take up to fifty hours. Thus, it depends on the design and expertise of the artist.</p></blockquote>
                    <figcaption className="blockquote-footer mt-3">From doingtattoo website</figcaption>  </figure>


                <h1>How Long Does A Tattoo Take To Heal?</h1>
                <p>At this part after care is really important</p>
                <figure>
                    <blockquote className="blockquote">
                        <p>The tattoo healing process is dependent on several factors. It depends on your healing capability as well as seasonal changes. Many artists say that crucial time starts from getting a tattoo to up to 6 weeks. The healing process takes place during this period.

                            Avoid doing anything harmful to your tattooed area during this period. Protect it from heat, sunlight, and avoid using harmful creams.</p></blockquote>
                    <figcaption className="blockquote-footer mt-3">From doingtattoo website</figcaption>
                </figure>
            </div>
            <Footer />
        </>)
}
export default Faq;