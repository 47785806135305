import Ryan from './imgs/Ryanz.jpg';
import Ken from './imgs/KdoCard.jpg';
import Axis from './imgs/Axis.jpg';
import Kulog from './imgs/kulog.jpg';
//import BG from './imgs/Bg_tattoo.jpg';
import './style.css';
import Navbar from './navibar';
import Footer from './footer';
import { Link } from 'react-router-dom';


const ENDPOINT = 'http://localhost:3001'

const addAppointment = async e => {

    console.log(e);
    const appointment = {
        FirstName: e.Fname,
        LastName: e.Lname,
        Age: e.Age,
        Address: e.Address,
        Email: e.Email,
        Artist: e.Artist
    }

    await fetch(`${ENDPOINT}/admin/appointment`, {
        method: 'POST',
        mode: 'cors',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(appointment)
    }).then(response => response.json())
        .then(data => {
            alert('your appointment was successfully saved');
            document.forms['appointment'].reset();
        }).catch(
            err => console.error(err.message)
        );
}
function Home() {


    const handleSubmit = e => {
        e.preventDefault();
        if (!e.target[0].value) return;
        const formData = new FormData();
        formData.Fname = e.target[0].value;
        formData.Lname = e.target[1].value;
        formData.Age = e.target[2].value;
        formData.Address = e.target[3].value;
        formData.Email = e.target[4].value;
        formData.Artist = e.target[5].value;
        addAppointment(formData);
    }
    return (
        <>
            <Navbar />

            <div className="h-100 p-5 m-5 text-bg-dark rounded-3">
                <h1>What to expect ? </h1>
                <p>We would like to provide an overview to every artist that we have. </p>
                <p>Every artist has their own specialty. </p>
                <p> Which one do you think will suit your taste ? </p>
            </div>
            {/* First artist */}
            <div className="container">
                <div className="row">
                    <h2 className='text-white mb-3'>Available artist beta </h2>

                    <div className="col-md-6 col-sm-6 d-flex justify-content-center align-item-center">
                        <div className="card bg-transparent text-white text-center" style={{ width: '400px' }}>
                            <img className="card-img-top img-thumbnail" src={Ryan} alt="Card"
                                style={{ width: '100%', height: '300px' }} />
                            <div className="card-body">
                                <h4 className="card-title">Ryan (Ryanz Tattoo)</h4>
                                <p className="card-text">Been doing tattoo for almost 10 years now. Won several awards in several competitions.
                                </p>
                                <Link to="../artist/ryan" className="btn btn-danger">See more</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6 d-flex justify-content-center align-item-center">

                        <div className="card bg-transparent text-white text-center" style={{ width: '400px' }}>
                            <img className="card-img-top img-fluid img-thumbnail" src={Ken} alt="Card"
                                style={{ width: '100%', height: '300px' }} />
                            <div className="card-body">
                                <h4 className="card-title">Ken (KDO)</h4>
                                <p className="card-text">Ken is a professional tattoo artist and body piercer</p>
                                <Link to="../artist/kdo" className="btn btn-danger">See more</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Second artist */}

            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-6 d-flex justify-content-center align-item-center">
                        <div className="card bg-transparent text-white text-center" style={{ width: '400px' }}>
                            <img className="card-img-top img-thumbnail" src={Axis} alt="Card" style={{ width: '100%' }} />
                            <div className="card-body">
                                <h4 className="card-title">Alex (Axis Tattoo)</h4>
                                <p className="card-text my-2">Alex is the most tenured artist among this 4 he even teach 3 of them when they are
                                    starting.</p>

                                <Link to="../artist/axis" className="btn btn-danger">See more</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6 d-flex justify-content-center align-item-center">
                        <div className="card bg-transparent text-white text-center" style={{ width: '400px' }}>
                            <img className="card-img-top img-thumbnail" src={Kulog} alt="Card"
                                style={{ width: '100%', height: ' auto' }} />
                            <div className="card-body">
                                <h4 className="card-title">Paolo (Kulog Tattoo)</h4>
                                <p className="card-text">Paolo is a beginner artist. However you can rest assured that on his specialty you
                                    won't
                                    feel worried. He was awarded in Dutdutan Tattoo Con as Champion in beginner Category</p>
                                <Link to="../artist/kdo" className="btn btn-danger">See more</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container rounded-5 my-3">
                <div className=" rounded bg-dark px-4 py-2 text-white" id="Bookform">
                    <form id='appointment' onSubmit={handleSubmit}>
                        <h3 className="text-center fw-bold mt-2">Book now!</h3>
                        <div className="mb-3">
                            <label htmlFor="FName" className="form-label">First Name:</label>
                            <input type="FName" className="form-control" id="Fname" size="50" placeholder="Enter First Name" required name="Fname" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="LName" className="form-label">Last Name:</label>
                            <input type="LName" className="form-control" id="Lname" size="50" placeholder="Enter Last Name" required name="Lname" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="text" className="form-label">Age</label>
                            <input type="number" className="form-control" id="Age" min={18} max={100} placeholder="Enter your Age"
                                name="Age" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="text" className="form-label">Address:</label>
                            <input type="text" className="form-control" size="50" id="Address" placeholder="Enter Address" required name="Address" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email:</label>
                            <input type="email" className="form-control" size="50" id="Address" placeholder="Enter Email" required name="Email" />
                        </div>

                        <select className="form-select mt-5">
                            <option>Choose your Artist Now!</option>
                            <option id='Artist'>Ryan</option>
                            <option id='Artist'>Ken</option>
                            <option id='Artist'>Alex</option>
                            <option id='Artist'>Paolo</option>
                        </select>
                        <div className="d-flex justify-content-center align-item-center my-2">
                            <button type="submit" className="btn btn-danger mt-5" id="ArtistBtn">Submit</button>
                        </div>
                    </form>

                </div>
            </div>
            <Footer />

        </>);


}
export default Home;