import Navibar from "./Navbar";

const ENDPOINT = 'http://localhost:3000/beus/admin/applicant'

const addApplicant = async e => {

    console.log(e);
    const applicant = {
        FirstName: e.Fname,
        LastName: e.Lname,
        Age: e.Age,
        Address: e.Address,
        Email: e.Email,
        Technique: e.Technique

    }
    // const check =
    await fetch(ENDPOINT, {
        method: 'POST',
        mode: 'cors',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(applicant)
    })
        //console.log(check.ok)
        .then(response => response.json())
        .then(data => {
            alert('your applicant was successfully saved');
            document.forms['applicant'].reset();
        }).catch(
            err => console.error(err.message)
        );

}


function Beus() {

    const handleSubmit = e => {
        e.preventDefault();
        if (!e.target[0].value) return;
        const formData = new FormData();
        formData.Fname = e.target[0].value;
        formData.Lname = e.target[1].value;
        formData.Age = e.target[2].value;
        formData.Address = e.target[3].value;
        formData.Email = e.target[4].value;
        formData.Technique = e.target[5].value;
        addApplicant(formData);
    }
    return (
        <>
            <Navibar />
            <div className="h-100 p-5 m-5 text-bg-dark rounded-3">
                <h1> Welcome dearest Applicant </h1>
                <p>We would like to expand our coverage around the globe feel free to send your details here, we will determine
                    and keep your details safe on our page. We are please to invite you to our journey in reaching every tattoo
                    head in the world! </p>
            </div>

            <div className="container rounded-5 my-3">
                <div className=" rounded bg-dark px-4 py-2 text-white" id="Bookform">
                    <form id="application" onSubmit={handleSubmit}>
                        <h3 className="text-center fw-bold mt-2">Send your application now!</h3>
                        <div className="mb-3">
                            <label htmlFor="FName" className="form-label">First Name:</label>
                            <input type="FName" className="form-control" id="Fname" size="50" placeholder="Enter First Name" required name="Fname" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="LName" className="form-label">Last Name:</label>
                            <input type="LName" className="form-control" id="Lname" size="50" placeholder="Enter Last Name" required name="Lname" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="text" className="form-label">Age</label>
                            <input type="number" className="form-control" id="Age" min={18} max={100} placeholder="Enter your Age"
                                name="Age" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="text" className="form-label">Address:</label>
                            <input type="text" className="form-control" size="50" id="Address" placeholder="Enter Address" required name="Address" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email:</label>
                            <input type="email" className="form-control" size="50" id="Address" placeholder="Enter Email" required name="Email" />
                        </div>

                        <select className="form-select mt-5">
                            <option >Choose your Experties:</option>
                            <option name="technique Traditional">Traditional / Old School Tattoo Style</option>
                            <option name="technique Neo Traditional">Neo Traditional Tattoo Style</option>
                            <option name="technique New School ">New School Tattoo Style</option>
                            <option name="technique Black and Grey">Black and Grey Tattoo Style</option>
                            <option name="technique Realism">Realism Tattoo Style</option>
                            <option name="technique Japanese">Japanese Tattoo Style</option>
                            <option name="technique Tribal">Tribal Tattoo Style</option>
                        </select>
                        <div className="d-flex justify-content-center align-item-center my-2">
                            <button type="submit" className="btn btn-danger my-3" id="ArtistBtn">Submit</button>
                        </div>
                    </form>

                </div>
            </div>
        </>

    );
}

export default Beus;