import { Link } from 'react-router-dom';
import Ryan1 from '../imgs/ryanz1.jpg';
import Ryan2 from '../imgs/ryanz2.jpg';
import Ryan3 from '../imgs/ryanz3.jpg';
import Ryan4 from '../imgs/ryanz4.jpg';


function Ryan() {
    return (
        <div className="container mt-5 text-white">

            <div className="d-grid gap-3 col-10 mx-auto">

                <div className="mt-5 p-5 bg-dark text-white rounded">
                    <h1>RyanZ Tattoo </h1>
                    <p>Would like to know more about Ryanz Tattoo ?  Checkout his Social Media Page! </p>
                    <h3>Specialty: Realistic  </h3>

                    <form action="https://www.facebook.com/profile.php?id=10006406944907"></form>
                    <Link to="https://www.facebook.com/profile.php?id=100064069449071" target="_blank">  <i className="fa-brands fa-facebook fa-3x"></i> </Link>

                </div>
            </div>


            <div id="demo" className="carousel slide" data-bs-ride="carousel">

                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="3"></button>
                </div>

                <div id="header-carousel" className="carousel slide" data-ride="carousel">

                    <div className="carousel-inner">

                        <div className="carousel-item active" id="slide1">
                            <img src={Ryan1} alt="ryanz1" style={{ height: '620px', width: '780px', MarginLeft: '20%', marginTop: '60px' }} id=
                                "carousel" className="img-thumbnail" />
                        </div>
                        <div className="carousel-item" id="slide2">
                            <img src={Ryan2} alt="ryanz2" style={{ height: '620px', width: '780px', MarginLeft: '20%', marginTop: '60px' }} id=
                                "carousel" className="img-thumbnail" />
                        </div>
                        <div className="carousel-item" id="slide3">
                            <img src={Ryan3} alt="ryanz3" style={{ height: '620px', width: '780px', MarginLeft: '20%', marginTop: '60px' }} id=
                                "carousel" className="img-thumbnail" />
                        </div>
                        <div className="carousel-item" id="slide4">
                            <img src={Ryan4} alt="ryanz4" style={{ height: '620px', width: '780px', MarginLeft: '20%', marginTop: '60px' }} id=
                                "carousel" className="img-thumbnail" />
                        </div>
                    </div>

                </div>

                <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon"></span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                    <span className="carousel-control-next-icon"></span>
                </button>
            </div>

            <div className="d-flex justify-content-center align-item-center my-5">
                <Link button type="submit" className="btn btn-danger btn-lg" to="../" id="ArtistSelec">
                    Back to Artist selection
                </Link>
            </div>
        </div>
    )
}

export default Ryan;