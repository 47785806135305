import React, { useEffect, useState } from 'react';
import { api } from '../../api';
function Application() {
    const [applicationlist, setApplicationlist] = useState([]);
    const ENDPOINT = `${api.ENDPOINT}/admin/application`;

    useEffect(() => {
        fetch(ENDPOINT, {
            method: 'GET',
            mode: 'cors',
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                // const newTasks = [...tasks, ...result];
                // let newTasks = [...tasks];
                // newTasks = [...newTasks, ...data];
                setApplicationlist([...data]);
            })
            .catch((error) => console.log('error', error));
    }, []);


    return (
        <div
            className="container bg-dark mt-5 border"
            style={{
                boxShadow: '0px 0px 15px -5px',
                borderRadius: '5px',
                fontSize: '0.8rem',
                backgroundColor: '#ffffff',
            }}
        >
            <div className="container-fluid table-responsive-lg table-responsive-md table-responsive-sm">
                <div className="my-5 text-white">
                    <h3>Applications</h3>
                </div>

                <table className=" text-white table border border-2  overflow-auto">
                    <thead>
                        <tr>
                            <th scope="col">Application ID</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Age</th>
                            <th scope="col">Address</th>
                            <th scope="col">Email</th>
                            <th scope="col">Artist Name</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody style={{ fontSize: '0.8rem' }}>
                        {applicationlist.map((applicationlist, index) => (
                            <tr key={applicationlist.id}>
                                <th scope="row">{applicationlist.id}</th>
                                <td>{applicationlist.FName}</td>
                                <td>{applicationlist.LName}</td>
                                <td>{applicationlist.Age}</td>
                                <td>{applicationlist.address}</td>
                                <td>{applicationlist.email}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Application;