import React, { useState } from "react";
import { Link } from "react-router-dom"; // If using React Router
import "./navbar.css"; // Import CSS file

import Logo from "./imgs/LOGO.png";

const Style = {
    logo: {
        Width: '50px'
    }
}

function Navbar() {

    const [isOpen, setIsOpen] = useState(false);

    // Function to toggle the menu open and closed
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav >
            <div className="navbar-container bg-dark text-white">
                <div className="logo-container">
                    <Link to="/"> <img src={Logo} styles={Style.logo} /> </Link>
                </div>

                <div className="menu-icon" onClick={toggleMenu}>
                    <i className={isOpen ? "fas fa-times" : "fas fa-bars"}></i>
                </div>

                <ul className={isOpen ? "menu-list open" : "menu-list"}>
                    <li>
                        <Link to="/" onClick={toggleMenu} className="text-white">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/" onClick={toggleMenu} className="text-white">
                            Be 1 of Us
                        </Link>
                    </li>
                    <li>
                        <Link to="/" onClick={toggleMenu} className="text-white" >
                            FAQs
                        </Link>
                    </li>
                    <li>
                        <Link to="/" onClick={toggleMenu} className="text-white">
                            About Us
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;
