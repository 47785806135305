import React from 'react';
import { Outlet } from 'react-router-dom';
import Application from './application';
import Logo from '../Home/imgs/LOGO.png';


function Landing() {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top pt-3">
                <div className="container-fluid justify-content-evenly">
                    <a className="navbar-brand" href="#">
                        <h3 className=" fw-bold fs-lg-1 fs-md-4">
                            <img
                                src={Logo}
                                alt="logo"
                                style={{ width: '100px' }}
                            />
                        </h3>
                    </a>
                </div>
            </nav>

            <div className="container">
                <div className="container">
                    <Outlet />
                    <Application />

                </div>
            </div>

        </>
    )
}

export default Landing;