import Footer from "./footer";
import Navbar from "./Navbar";


function About() {
    return (
        <>
            <Navbar />
            <div className="container mt-5 pt-5 text-white">
                <h1>What we do ? </h1>
                <p>TattooIt vission and mission</p>
                <blockquote className="blockquote">
                    <h1>Mission</h1>
                    <p>We are targetting worldwide ease for people who is looking forward or thinking of having a tattoo. We want to
                        let everyone to have a glimpse in the world of the people who is hooked or in to Tattoos. This time around
                        people who is being interrested in getting a tattoo will not need to be afraid to be judged as the people that
                        they will going to deal with in this website are people who is in to the world that they are planning to be in
                    </p>
                </blockquote>


                <blockquote className="blockquote">
                    <h1>Vision</h1>
                    <p>To gather all the artists around the world in one platform! To make sure that tattoo heads can access every
                        artists around the world and choose from them by providing their specialty and their level of creativity.</p>
                </blockquote>

                <h1>History </h1>
                <blockquote className="blockquote">

                    <p>We initially started with circle of friends with one developer that is into tattoo and 4 artists that he work
                        with in getting his own tattoo.</p>
                </blockquote>
            </div>
            <Footer />
        </>)
}
export default About;