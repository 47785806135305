import { Link } from 'react-router-dom';
import Kdo1 from '../imgs/kdo1.jpg';
import Kdo2 from '../imgs/KDO2.jpg';
import Kdo3 from '../imgs/kdo3.jpg';
import Kdo4 from '../imgs/kdo4.jpg';


export default function Kdo() {
    return (
        <>
            <div className="container mt-5 text-white">

                <div className="d-grid gap-3 col-10 mx-auto">

                    <div className="mt-5 p-5 bg-dark text-white rounded">
                        <h1>KEN (KDO Tattoo) </h1>
                        <p>Would like to know more about KDO Tattoo ?  Checkout his Social Media Page! </p>
                        <h3>Specialty: Classic American  </h3>

                        <form action="https://www.facebook.com/KDOPIERCINGandTATTOO"></form>
                        <Link to="https://www.facebook.com/KDOPIERCINGandTATTOO" target="_blank">  <i className="fa-brands fa-facebook fa-3x"></i> </Link>

                    </div>
                </div>


                <div id="demo" className="carousel slide" data-bs-ride="carousel">


                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
                        <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                        <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
                        <button type="button" data-bs-target="#demo" data-bs-slide-to="3"></button>
                    </div>

                    <div id="header-carousel" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active" id="slide1">
                                <img src={Kdo1} alt="ryanz1" style={{ height: '620px', width: '780px', MarginLeft: '20%', marginTop: '60px' }} className="img-thumbnail" />
                            </div>
                            <div className="carousel-item" id="slide2">
                                <img src={Kdo2} alt="ryanz2" style={{ height: '620px', width: '780px', MarginLeft: '20%', marginTop: '60px' }} className="img-thumbnail" />
                            </div>
                            <div className="carousel-item" id="slide3">
                                <img src={Kdo3} alt="ryanz3" style={{ height: '620px', width: '780px', MarginLeft: '20%', marginTop: '60px' }} className="img-thumbnail" />
                            </div>
                            <div className="carousel-item" id="slide4">
                                <img src={Kdo4} alt="ryanz4" style={{ height: '620px', width: '780px', MarginLeft: '20%', marginTop: '60px' }} className="img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon"></span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                        <span className="carousel-control-next-icon"></span>
                    </button>
                </div>

                <div className="d-flex justify-content-center align-item-center my-5">

                    <Link button type="submit" className="btn btn-danger btn-lg" to="../" id="ArtistSelec">
                        Back to Artist selection
                    </Link></div>
            </div></>)

};