import Logo from './imgs/LOGO.png';
import { Link } from 'react-router-dom';


const Style = {
    logosize: {
        width: "100px"
    },
}

function Footer() {
    return (
        <>
            <footer>
                <div className="content">
                    <div className="top">
                        <div className="logo-details">
                            <span className="logo_name"><img src={Logo} alt="" style={Style.logosize} /></span>
                        </div>
                        <div className="media-icons">
                            <Link to="#"><i className="fab fa-facebook-f"></i></Link>
                            <Link to="#"><i className="fab fa-twitter"></i></Link>
                            <Link to="#"><i className="fab fa-instagram"></i></Link>
                            <Link to="#"><i className="fab fa-linkedin-in"></i></Link>
                            <Link to="#"><i className="fab fa-youtube"></i></Link>
                        </div>
                    </div>
                    <div className="row text-center text-white">
                        <div className="col">
                            <h2>Schedules</h2>
                        </div>
                        <div className="col">
                            <h2>Location</h2>
                        </div>
                        <div className="col">
                            <h2>Artist</h2>
                        </div>
                    </div>
                </div>
                <div className="bottom-details">
                    <div className="bottom_text">
                        <span className="copyright_text">Copyright © 2023 <Link to="#">TattooIt.</Link>All rights reserved</span>
                        <span className="policy_terms">
                            <Link to="#">Privacy policy</Link>
                            <Link to="#">Terms & condition</Link>
                        </span>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;